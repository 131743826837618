<template>
	<div id="commentSubmit">
		<Navigation />
		<ClassifyChoose></ClassifyChoose>
		<SideNavigation></SideNavigation>
		<Breadcrumb></Breadcrumb>

		<div class="commentSubmitbox">
			<div class="commentSubmitbox-t flc">
				<div class="commentSubmitbox-t-l">
					{{orderInfo.updated}}
				</div>
				<div class="commentSubmitbox-t-r">
					订单编号：{{orderInfo.number}}
				</div>
				<!-- 客服 -->
				<!-- <img src="../../assets/img/kefu.png" style="width: 20px;height: 20px;margin-left: 60px;" @click="clickkefu" > -->
			</div>

			<div class="commentSubmitbox-c flcs" v-for="(item,index) in CommentInfo" :key="index">
				<el-image class="commentSubmitbox-c-i"
					:src="item.cover"></el-image>

				<div class="commentSubmitbox-c-t line-two">
					{{item.title}}
				</div>
				<div class="commentSubmitbox-c-r">
					<div class="commentSubmitbox-c-r-t">
						¥{{orderInfo.payMoney}}
					</div>
					<div class="commentSubmitbox-c-r-b">
						（含配送费¥{{orderInfo.postage}}）
					</div>
				</div>
			</div>

			<div class="commentSubmitbox-b">
				<div class="commentSubmitbox-b-l"><!-- action="https://www.sywxxcx.com/short_selling/front/sundry/upload" -->
					<el-input class="commentSubmitbox-b-l-t" type="textarea" placeholder="请输入内容" v-model="textarea">
					</el-input>
					<el-upload style="margin-top: 20px;"
					  class="upload-demo"
					  
					  action="https://www.wumaikong.com/short_selling/front/sundry/upload"
					  :on-preview="handlePreview"
					  :on-remove="handleRemove"
					  :before-remove="beforeRemove"
					  :on-success="shen"
					  multiple
					  :limit="3"
					  :on-exceed="handleExceed"
					  :file-list="fileList">
					  <el-button size="small" type="primary">点击上传</el-button>
					  <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>
					</el-upload>
					<!-- <div class="commentSubmitbox-b-b" >
						<img class="commentSubmitbox-b-b-l" src="../../assets/img/addpic.png">
						<div class="commentSubmitbox-b-b-r">
							晒图片
						</div>
					</div> -->
				</div>
				<div class="commentSubmitbox-b-r" @click="commentUPdate()">
					发布评价
				</div>
			</div>
		</div>
		
		<kefu v-if="shows"></kefu>
		<Footer></Footer>
	</div>
</template>

<script>
	import Navigation from "../common/navigation.vue"
	import ClassifyChoose from "../common/classify_choose.vue"
	import Breadcrumb from "../common/breadcrumb.vue"
	import SideNavigation from "../common/side_navigation.vue"
	import Footer from "../common/footer.vue"
	import kefu from "../common/kefu.vue"
	import {
		orderinfo,
		orderAddComment
	} from "../../utils/index.js"
	import axios from "axios"
	
	
	export default {
		name: "commentSubmit",
		components: {
			Navigation,
			ClassifyChoose,
			Breadcrumb,
			SideNavigation,
			Footer,
			kefu
		},
		data() {
			return {
				textarea: "",
				oid: "",
				itemId:'',
				CommentInfo:[],
				orderInfo:[],
				fileList: [],
				banner:[],
				shows:false
			}
		},
		created() {
			if (this.$route.query.cc||this.$route.query.cid) {
				this.oid = this.$route.query.cc
				this.itemId=this.$route.query.cid
				this.orderinfo()
			}
		},
		methods: {
			// changeFile(e){
			// // 获取文件信息 e.target.files
			// console.log (e.target.files[0])
			// this.file = e.target.files[0]
			// },
			// updateIMG (){
			// 	 this.$refs.fileRef.dispatchEvent(new MouseEvent('click'))
			// },
				shen(file,fileList){
					this.banner.push(file)
					 //console.log(file, fileList);
				},
					handleRemove(file, fileList) {
						this.banner=[]
			       // console.log("1",file, fileList);
					fileList.forEach((e)=>{
						
						this.banner.push(e.response)
					})
					//console.log("999999999999999999",this.banner)
			      },
			      handlePreview(file) {
			        //console.log("2",file);
			      },
			      handleExceed(files, fileList) {
			        this.$message.warning(`当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
			      },
			      beforeRemove(file, fileList) {
			        return this.$confirm(`确定移除 ${ file.name }？`);
			      },
			// 订单详情
			orderinfo() {
				orderinfo({
					oid: this.oid
				}).then(res => {
					//console.log("订单详情", res);
					this.orderInfo=res.data.order
					this.CommentInfo=res.data.orderItem
					
				})
			},
			//添加评论
			orderAddComment(){
				//console.log(this.banner.toString())
				orderAddComment({
					banner:this.banner.toString(),
					content:this.textarea,
					itemId:this.itemId,
					oid:this.oid,
				}).then(res=>{
					//console.log("添加评论",res)
					if(res.code==200){
						this.$message({
							message: '发布成功',
							type: 'success'
						});
						setTimeout(()=>{
							this.$router.push({
								path:'/userinfo',
								query:{
									type:3
								}
							});
						},1000)
					}else{
						this.$message({
							message: '发布失败',
							type: 'error'
						});
					}
				})
			},
			commentUPdate(){
				this.orderAddComment()
				
			},
			/* clickkefu(){
				this.shows=!this.shows
			} */
		}
	}
</script>

<style lang="less">
	@import url("userinfo.less");
</style>
